<template>
  <div class="order-management">
    <!-- <div class="prediction">
      <div style="padding: 8px">
        <nqForm
          :submitText="submitText"
          @submit="submitForm"
          @cancel="cancelForm"
          :values="formValue"
          :formList="[
            {
              label: '手机',
              key: 'mobilePhone',
              type: 'input',
              placeholder: '请输入手机号码',
              norequire: true,
            },
            {
              label: '邮箱',
              key: 'mailBox',
              type: 'input',
              placeholder: '请输入邮箱号',
              norequire: true,
            },
            {
              label: '微信',
              key: 'weChat',
              type: 'input',
              placeholder: '请输入微信号码',
              norequire: true,
            },
            {
              label: 'QQ',
              key: 'qq',
              type: 'input',
              placeholder: '请输入QQ号码',
              norequire: true,
            },
          ]"
        ></nqForm>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      // showDetail: false,
      // formValue: {
      //   mobilePhone: "",
      //   mailBox: "",
      //   weChat: "",
      //   qq: "",
      // },
    };
  },

  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
@import url("../order-management/order.less");
</style>